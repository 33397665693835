import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DepartmentSelect from './DepartmentSelect';

const ProfileForm = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [department, setDepartment] = useState(null);
  const [userMetadata, setUserMetadata] = useState(null);

  const handleDepartmentChange = (selectedOption) => {
    setDepartment(selectedOption ? selectedOption.value : null);
  };

  const updateProfile = async () => {
    // Function to get the value of a URL parameter
    const getUrlParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'ts' parameter from the URL
    const timestamp = getUrlParameter('ts');

    // Construct the request body
    const body = {
      userId: user.sub,
      profileData: { 
        organization: 'Testing', 
        department: department,
        timestamp: timestamp 
      }
    };

    console.log('Request body:', body);

    try {
      const response = await fetch('https://us-central1-dlab-berkeley-auth0.cloudfunctions.net/updateProfile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error details:', JSON.stringify(errorDetails));
        throw new Error(`Failed to update profile: ${JSON.stringify(errorDetails)}`);
      }

      const data = await response.json();
      console.log('Profile updated successfully', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchUserMetadata = async () => {
    const domain = "dev-7y07j33qpbx3025t.us.auth0.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { user_metadata } = await metadataResponse.json();

      setUserMetadata(user_metadata);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchUserMetadata();
  }, []);

  return (
    <div>
      <h3>Select your department:</h3>
      <DepartmentSelect onChange={handleDepartmentChange} />
      <button onClick={updateProfile}>Submit</button>
      <div>
        <h3>Your Profile:</h3>
        <pre>{JSON.stringify(user, null, 2)}</pre>
        {userMetadata ? (
          <div>
            <h3>User Metadata:</h3>
            <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
          </div>
        ) : (
          "No user metadata defined"
        )}
      </div>
    </div>
  );
};

export default ProfileForm;
