import React from 'react';
import Select from 'react-select';

const departments = [
  { value: 'Computer Science', label: 'Computer Science' },
  { value: 'Mathematics', label: 'Mathematics' },
  { value: 'Physics', label: 'Physics' },
  // Add more departments as needed
];

const DepartmentSelect = ({ onChange }) => (
  <Select
    options={departments}
    onChange={onChange}
    placeholder="Select your department"
    isClearable
    styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }}
  />
);

export default DepartmentSelect;
