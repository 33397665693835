import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProfileForm from './components/ProfileForm';
import { useAuth0 } from '@auth0/auth0-react';
import Callback from './components/Callback';
import LoginButton from './components/LoginButton';

const App = () => {
  const { loginWithRedirect, loginWithPopup, logout, user, isAuthenticated } = useAuth0();

  const handleBerkeleyLogin = () => {
    console.log('Initiating login with UC Berkeley...');
    loginWithRedirect({ connection: 'ucberkeley' });
  };

  const handlePasswordlessLogin = async () => {
    const email = prompt('Enter your email for the magic link:');
    if (email) {
      console.log('Initiating passwordless login with email:', email);
      await loginWithPopup({
        connection: 'email',
        send: 'link',
        email,
      });
    }
  };

  return (
    <div className="App">
      <Switch>
        <Route path="/callback" component={Callback} />
        <Route path="/">
          {!isAuthenticated ? (
            <div>
              <LoginButton />
            </div>
          ) : (
            <div>
              <h2>Welcome, {user.name}</h2>
              <button onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
              <ProfileForm />
              <div>
                <h3>Your Profile:</h3>
                <pre>{JSON.stringify(user, null, 2)}</pre>
              </div>
            </div>
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default App;
