import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Callback = () => {
  const { handleRedirectCallback, error, isLoading } = useAuth0();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        console.log('Handling redirect callback...');
        await handleRedirectCallback();
        console.log('Redirect callback handled successfully.');
      } catch (err) {
        console.error('Error handling redirect callback:', err);
      }
    };
    handleAuthCallback();
  }, [handleRedirectCallback]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error('Error state detected:', error);
    return <div>Oops... {error.message}</div>;
  }

  return <div>Loading...</div>;
};

export default Callback;
